import './Performance.scss';
import React from 'react';
import Typography from '@components/Typography';
import Container from '@components/Container';
import innerCircle from './assets/inner-circle.png';
import outerCircle from './assets/outer-circle.png';
import rotatingCircle from './assets/rotating-circle.png';

interface BenchmarkProps {
  title: string;
  times: number;
}

const Benchmark: React.FC<BenchmarkProps> = (props) => {
  const { title, times } = props;

  return (
    <div className="TDB-HomePage__Performance__Benchmark">
      <Typography
        fontSize="heading-4"
        fontWeight="bold"
        className="TDB-HomePage__Performance__Benchmark__Title TDB-HomePage__Performance-text-accent"
      >
        {title}
      </Typography>
      <Typography fontSize="heading-5" fontWeight="medium" color="neutral-300" className="TDB-HomePage__Performance__Benchmark__UpTo">
        up to
      </Typography>
      <Typography fontSize="display-2" fontWeight="bold" color="neutral-main" className="TDB-HomePage__Performance__Benchmark__Times">
        {times}x
      </Typography>
      <Typography fontSize="body-lg" color="neutral-400" fontWeight="semi-bold" className="TDB-HomePage__Performance__Benchmark__Faster">
        faster, cheaper
      </Typography>
    </div>
  );
};

const RibbonItem: React.FC = (props) => {
  const { children } = props;

  return (
    <div className="TDB-HomePage__Performance__RibbonItem">
      <Typography fontSize="heading-5" color="neutral-main" fontWeight="medium">
        {children}
      </Typography>
    </div>
  );
};

const Performance: React.FC = () => {
  return (
    <div className="TDB-HomePage__Performance">
      <div className="TDB-HomePage__Performance__Content">
        <div className="TDB-HomePage__Performance__CircleWrapper">
          <img className="TDB-HomePage__Performance__Circle TDB-HomePage__Performance__Circle--inner" src={innerCircle} alt="" />
          <img className="TDB-HomePage__Performance__Circle TDB-HomePage__Performance__Circle--outer" src={outerCircle} alt="" />
          <img className="TDB-HomePage__Performance__Circle TDB-HomePage__Performance__Circle--rotating" src={rotatingCircle} alt="" />
        </div>
        <div className="TDB-HomePage__Performance__InnerContent">
          <Container>
            <div className="TDB-HomePage__Performance__Text">
              <Typography
                as="h3"
                className="TDB-HomePage__Performance__Title"
                fontWeight="bold"
                fontSize="heading-1"
                color="neutral-main"
                align="center"
              >
                <span className="TDB-HomePage__Performance-text-accent">Superior</span> performance <br /> at{' '}
                <span className="TDB-HomePage__Performance-text-smooth">lowest</span> cost
              </Typography>
              <Typography
                as="p"
                className="TDB-HomePage__Performance__Description"
                fontWeight="medium"
                fontSize="heading-5"
                color="neutral-300"
                align="center"
              >
                Structure all your data in a canonical, multi-dimensional array format, which adapts to any data shape and workload for
                maximum performance and minimum cost.
              </Typography>
            </div>
            <div className="TDB-HomePage__Performance__Value">
              <Typography fontWeight="bold" color="neutral-main">
                $
              </Typography>
              <Typography fontWeight="semi-bold" color="neutral-600">
                $$
              </Typography>
              <Typography fontWeight="semi-bold" color="neutral-600">
                $$$
              </Typography>
            </div>
          </Container>
          <Container className="TDB-HomePage__Performance__BenchmarksContainer">
            <div className="TDB-HomePage__Performance__Benchmarks">
              <div className="TDB-HomePage__Performance__Benchmarks__Bullets">
                <div />
                <div />
                <div />
              </div>
              <Benchmark title="Time Series" times={50} />
              <Benchmark title="Genomics" times={100} />
              <Benchmark title="Point Cloud" times={30} />
              <div className="TDB-HomePage__Performance__Benchmarks__Bullets TDB-HomePage__Performance__Benchmarks__Bullets--right">
                <div />
                <div />
                <div />
              </div>
            </div>
          </Container>
          <Container>
            <div className="TDB-HomePage__Performance__Ribbon">
              <div className="TDB-HomePage__Performance__Ribbon__Gradient" />
              <div className="TDB-HomePage__Performance__Ribbon__Inner">
                <RibbonItem>Rapid slicing</RibbonItem>
                <RibbonItem>No wrangling</RibbonItem>
                <RibbonItem>Extreme scale</RibbonItem>
                <RibbonItem>Universal format</RibbonItem>
                <RibbonItem>Multi-tenant</RibbonItem>
                <RibbonItem>Serverless</RibbonItem>
                <RibbonItem>Cloud-optimized</RibbonItem>
                <RibbonItem>Elastic</RibbonItem>
                <RibbonItem>Rapid slicing</RibbonItem>
                <RibbonItem>No wrangling</RibbonItem>
                <RibbonItem>Extreme scale</RibbonItem>
                <RibbonItem>Universal format</RibbonItem>
              </div>
              <div className="TDB-HomePage__Performance__Ribbon__Gradient" />
            </div>
          </Container>
        </div>
      </div>
      <div className="TDB-HomePage__Performance__Gradient" />
    </div>
  );
};

export default Performance;
