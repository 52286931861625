import React from 'react';
import './SectionStayConnectedForBlog.scss';
import HubspotForm from 'react-hubspot-form';
import { Link } from 'gatsby';
import links from '@constants/links';
import Typography from '../../../components/Typography';

const SectionStayConnectedForBlog = () => {
  return (
    <div className="TDB-SectionStayConnectedForBlog">
      <div className="TDB-SectionStayConnectedForBlog__Card">
        <div className="TDB-SectionStayConnectedForBlog__Card__Text">
          <Typography
            as="h5"
            color="neutral-main"
            fontSize="heading-3"
            fontWeight="bold"
            className="TDB-SectionStayConnectedForBlog__Card__Title"
          >
            Stay connected
          </Typography>
          <Typography
            as="div"
            fontSize="heading-5"
            color="brand-100"
            fontWeight="medium"
            className="TDB-SectionStayConnectedForBlog__Card__Subtitle"
          >
            Get product and feature updates.
          </Typography>
        </div>
        <div className="TDB-SectionStayConnectedForBlog__Card__Form">
          <HubspotForm portalId="7741339" formId="7051db39-14a0-4b57-941f-1e86516d3116" loading={<div>Loading form...</div>} />
          <Typography as="small" color="brand-100" className="terms" fontSize="caption-list">
            By subscribing you agree with TileDB, Inc. <Link to={links.terms}>Terms of use</Link>.
            <br />
            Your personal data will be processed in accordance with TileDB's <Link to={links.privacyPolicy}>Privacy Policy</Link>.
          </Typography>
          <Typography as="small" color="brand-100" className="terms--mobile" fontSize="caption-list">
            By subscribing you agree with TileDB, Inc. <Link to={links.terms}>Terms of use</Link>. Your personal data will be processed in
            accordance with TileDB's <Link to={links.privacyPolicy}>Privacy Policy</Link>.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SectionStayConnectedForBlog;
