import React from 'react';
import './index.scss';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import Hero from '@page-components/home/Hero';
// import TrustedBy from '@page-components/home/TrustedBy';
import DataInOnePlace from '@page-components/home/DataInOnePlace';
import Performance from '@page-components/home/Performance';
import TeamUp from '@page-components/home/TeamUp';
import Analytics from '@page-components/home/Analytics';
import Products from '@page-components/home/Products';
import shareImage from '@page-components/home/assets/TileDB_homepage_thumbnail.png';
import StayConnected from '@page-components/home/StayConnected';

const Home = () => {
  return (
    <IndexLayout
      pageName="home"
      helmet={{
        title: 'TileDB -The Modern Database',
        description: 'TileDB is the modern data stack in a box. All data, code, and compute in a single product.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent" />
      <main className="main">
        <Hero />
        {/* <TrustedBy /> */}
        <DataInOnePlace />
        <Performance />
        <TeamUp />
        <Analytics />
        <Products />
        <StayConnected />
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default Home;
