import './DataInOnePlace.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import InlineButton from '@components/InlineButton';
import links from '@constants/links';
import dataFormats from './assets/data-formats.png';
import arrays from './assets/arrays.svg';
import mlmodels from './assets/mlmodels.svg';
import notebooks from './assets/notebooks.svg';
import dashboards from './assets/dashboards.svg';
import udfs from './assets/udfs.svg';
import files from './assets/files.svg';
import groups from './assets/groups.svg';
import taskGraphs from './assets/task_graphs.svg';
import arrayOverview from './assets/array_overview.jpg';

interface AssetTypeProps {
  title: string;
  icon: string;
}

const AssetType: React.FC<AssetTypeProps> = (props) => {
  const { title, icon } = props;
  return (
    <div className="TDB-DataInOnePlace__AssetType">
      <div className="TDB-DataInOnePlace__AssetType__Icon">
        <img src={icon} alt={title} />
      </div>
      <Typography fontWeight="semi-bold" fontSize="body-lg" color="neutral-800">
        {title}
      </Typography>
    </div>
  );
};

interface ExploreButtonProps {
  className?: string;
}

const ExploreButton: React.FC<ExploreButtonProps> = (props) => {
  const { className } = props;

  return (
    <InlineButton className={className} variant="light" href={links.console.explore}>
      Explore public assets
    </InlineButton>
  );
};

const DataInOnePlace: React.FC = () => {
  return (
    <div className="TDB-DataInOnePlaceWrapper">
      <Container>
        <div className="TDB-DataInOnePlace">
          <div className="TDB-DataInOnePlace__Content">
            <Typography as="h3" className="TDB-DataInOnePlace__Title" color="neutral-900" fontWeight="bold" fontSize="heading-2">
              Every data modality
            </Typography>
            <Typography as="p" className="TDB-DataInOnePlace__Description">
              Manage any type of data – tables, files, images, video, genomics, ML features, metadata, even flat files and folders – as
              multi-dimensional arrays in a single powerful database.
            </Typography>
            <div className="TDB-DataInOnePlace__DataFormatsWrapper">
              <div className="TDB-DataInOnePlace__DataFormats">
                <img src={dataFormats} alt="data-formats" />
              </div>
            </div>
            <div className="TDB-DataInOnePlace__AssetTypes">
              <AssetType title="Arrays" icon={arrays} />
              <AssetType title="ML models" icon={mlmodels} />
              <AssetType title="Notebooks" icon={notebooks} />
              <AssetType title="Files" icon={files} />
              <AssetType title="UDFs" icon={udfs} />
              <AssetType title="Task Graphs" icon={taskGraphs} />
              <AssetType title="Dashboards" icon={dashboards} />
              <AssetType title="Groups" icon={groups} />
            </div>
            <ExploreButton className="TDB-DataInOnePlace__ExploreButton--desktop" />
          </div>
          <div className="TDB-DataInOnePlace__GraphicPlaceholder" />
        </div>
      </Container>
      <div className="TDB-DataInOnePlace__GraphicContainer">
        <div className="TDB-DataInOnePlace__Graphic">
          <img className="TDB-DataInOnePlace__Graphic__ArrayOverview" src={arrayOverview} alt="array overview" />
        </div>
      </div>
      <ExploreButton className="TDB-DataInOnePlace__ExploreButton--mobile" />
    </div>
  );
};

export default DataInOnePlace;
