import './Analytics.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import curve from './assets/curve.svg';
import blur from './assets/blur.png';

const Analytics: React.FC = () => {
  return (
    <div className="TDB-HomePage__AnalyticsWithCurve">
      <div className="TDB-HomePage__AnalyticsWrapper">
        <Container>
          <div className="TDB-HomePage__Analytics">
            <Typography as="h3" className="TDB-HomePage__Analytics__Title" fontWeight="bold" fontSize="display-1">
              Analytics.
              <br />
              Data science.
              <br />
              ML.
            </Typography>
            <Typography as="p" className="TDB-HomePage__Analytics__Description" fontSize="heading-5" color="brand-100" fontWeight="medium">
              Run data science and machine learning workloads in a single platform that unifies data management and analytics with
              scientific computation.
            </Typography>
          </div>
        </Container>
        <img src={blur} alt="" className="TDB-HomePage__AnalyticsWrapper__Blur" />
        <div className="TDB-HomePage__AnalyticsWrapper__Gradient" />
      </div>
      <div className="TDB-HomePage__Analytics__MobileImage">
        <div className="TDB-HomePage__Analytics__MobileImage__TopGradient" />
        <div className="TDB-HomePage__Analytics__MobileImage__BottomGradient" />
      </div>
      <img className="TDB-HomePage__AnalyticsWithCurve__Curve" src={curve} alt="" />
    </div>
  );
};

export default Analytics;
