import './Products.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import links from '@constants/links';
import InlineButton from '@components/InlineButton';
import tiledb from './assets/tiledb.svg';
import embedded from './assets/embedded.png';
import cloud from './assets/cloud.png';

interface CardContentProps {
  title: string;
  link: string;
  icon: string;
}

const CardContent: React.FC<CardContentProps> = (props) => {
  const { title, link, children, icon } = props;

  return (
    <div className="TDB-HomePage__Products__CardContent">
      <img className="TDB-HomePage__Products__CardContent__Icon" src={icon} alt={title} />
      <div className="TDB-HomePage__Products__CardContent__Inner">
        <div className="TDB-HomePage__Products__CardContent__Text">
          <img className="TDB-HomePage__Products__CardContent__Logo" src={tiledb} alt="tiledb" />
          <Typography className="TDB-HomePage__Products__CardContent__Title" fontSize="overline" fontWeight="bold" color="brand-900">
            {title}
          </Typography>
          <Typography className="TDB-HomePage__Products__CardContent__SubTitle" color="neutral-600" fontSize="body-lg" align="center">
            {children}
          </Typography>
        </div>
        <InlineButton to={link} variant="brand">
          Explore more
        </InlineButton>
      </div>
    </div>
  );
};

const Products: React.FC = () => {
  return (
    <div className="TDB-HomePage__ProductsWrapper">
      <Container>
        <div className="TDB-HomePage__Products">
          <Typography
            className="TDB-HomePage__Products__Title"
            as="h2"
            fontSize="heading-1"
            fontWeight="bold"
            color="neutral-900"
            align="center"
          >
            TileDB Tech
          </Typography>
          <Typography
            className="TDB-HomePage__Products__Subtitle"
            as="p"
            fontSize="subheading-3"
            fontWeight="medium"
            color="neutral-600"
            align="center"
          >
            Choose the solution that fits your needs
          </Typography>
          <div className="TDB-HomePage__Products__CardsWrapper">
            <div className="TDB-HomePage__Products__LargeCard">
              <CardContent title="OPEN-SOURCE" link={links.openSource.arrayStorage} icon={embedded}>
                TileDB develops a wide range of open-source tools for interoperability across the data science and scientific computing
                ecosystems.
              </CardContent>
              <CardContent title="CLOUD" link={links.cloud} icon={cloud}>
                TileDB Cloud is the modern database for reproducible code, generative AI and collaboration at global scale.
              </CardContent>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Products;
