import './Btn.scss';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import Typography from '../Typography';

type BtnType = 'button' | 'submit' | 'reset';
type BtnVariant = 'primary' | 'outline';
type BtnColor = 'brand' | 'black';

interface BtnProps {
  className?: string;
  variant?: BtnVariant;
  to?: string;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  iconRight?: boolean;
  alignContent?: 'left' | 'center' | 'right';
  icon?: string;
  type?: BtnType;
  target?: string;
  rel?: string;
  color?: BtnColor;
}

const Btn: React.FC<BtnProps> = (props) => {
  const {
    children,
    className,
    variant = 'primary',
    to,
    href,
    disabled,
    onClick,
    iconRight,
    alignContent,
    icon,
    type = 'button',
    target,
    rel,
    color = 'brand',
  } = props;

  const clsnm = classNames('TDB-Button', className, `TDB-Button--${variant}`, `TDB-Button--color-${color}`, {
    [`TDB-Button--align-${alignContent}`]: alignContent,
    'TDB-Button--icon-right': iconRight,
  });

  const content = (
    <>
      {icon && <img src={icon} alt="" className="TDB-Button__Icon" />}
      <Typography className="TDB-Button__Text" fontSize="button" fontWeight="semi-bold" color="neutral-900">
        {children}
      </Typography>
    </>
  );

  if (to) {
    return (
      <Link className={clsnm} to={to}>
        {content}
      </Link>
    );
  }
  if (href) {
    return (
      <a className={clsnm} href={href} target={target} rel={target === '_blank' ? `noopener noreferrer` : rel}>
        {content}
      </a>
    );
  }
  return (
    <button className={clsnm} type={type} disabled={disabled} onClick={onClick}>
      {content}
    </button>
  );
};

export default Btn;
