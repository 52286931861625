import * as React from 'react';
import Container from '@components/Container';
import SectionStayConnectedForBlog from '@page-components/Blog/SectionStayConnectedForBlog';
import './StayConnected.scss';

const StayConnected: React.FC = () => {
  return (
    <Container id="newsletter" className="TDB-StayConnected">
      <SectionStayConnectedForBlog />
    </Container>
  );
};

export default StayConnected;
