import './Hero.scss';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import Btn from '@components/Btn';
import Container from '@components/Container';
import Typography from '@components/Typography';
import links from '@constants/links';
import caretRight from './assets/caret-right.svg';

const Hero = () => {
  const refVideo = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }

    // open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
  }, [refVideo.current]);

  return (
    <>
      <div className="TDB-HomePage__HeroWrapper">
        <div className="TDB-HeroVideo">
          <video ref={refVideo} autoPlay loop muted playsInline>
            <source src="/tiledb_homepage_hero.mp4?utm_campaign=Brand%202023&utm_source=hero-banner" />
          </video>
          <div className="TDB-HeroVideo__Overlay top" />
          <div className="TDB-HeroVideo__Overlay right" />
          <div className="TDB-HeroVideo__Overlay bottom" />
          <div className="TDB-HeroVideo__Overlay left" />
          <div className="TDB-HeroVideo__Overlay left" />
        </div>
        <Container>
          <div className="TDB-HomePage__Hero">
            <div className="TDB-HomePage__Hero__content">
              <Typography
                href="/blog/tiledb-closes-series-b-to-advance-the-vision-of-the-modern-database/"
                className="TDB-HomePage__Banner"
                fontSize="button"
                color="neutral-800"
                as="a"
                fontWeight="semi-bold"
              >
                <img className="TDB-HomePage__Banner__Sparkles" src="/img/sparkles.svg" alt="sparkles" />
                <div className="TDB-HomePage__Banner__Text">
                  <Typography as="p" fontSize="body-lg" fontWeight="medium" color="neutral-main">
                    Announcing our $34M Series B
                  </Typography>
                  <Typography as="span" fontSize="body-sm" fontWeight="medium" color="neutral-600">
                    <span style={{ color: 'rgba(255, 255, 255, 0.60)' }}>Advancing the vision of the modern database</span>
                  </Typography>
                </div>
                <img className="TDB-HomePage__Banner__Caret" src={caretRight} alt="caret" />
              </Typography>

              <Typography
                as="h1"
                className={classNames('TDB-HomePage__Hero__Title')}
                fontSize="display-1"
                fontWeight="bold"
                color="neutral-main"
              >
                The Modern <span className="TDB-HomePage__Hero__Title--accent">Database</span>
              </Typography>

              <Typography
                as="span"
                className="TDB-HomePage__Hero__Description"
                fontSize="subheading-1"
                fontWeight="medium"
                color="neutral-100"
              >
                The modern data stack in a box:
                <br />
                all data, code and compute in a single product.
              </Typography>
              <div className="TDB-HomePage__Hero__Buttons">
                <Btn color="brand" variant="primary" to={links.console.signup}>
                  Get started
                </Btn>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Hero;
