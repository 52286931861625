import './TeamUp.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import check from './assets/check.svg';
import dorothy from './assets/dorothy.png';
import tobe from './assets/tobe.png';
import natalie from './assets/natalie.png';

const CheckItem: React.FC = (props) => {
  const { children } = props;

  return (
    <div className="TDB-HomePage__TeamUp__CheckItem">
      <img src={check} alt="check" />
      <Typography fontSize="subheading-3" color="neutral-800" fontWeight="medium">
        {children}
      </Typography>
    </div>
  );
};

interface TeamCardProps {
  img: string;
  name: string;
  title: string;
  interests: string;
  useCase: string;
}

const TeamCard: React.FC<TeamCardProps> = (props) => {
  const { img, name, title, interests, useCase } = props;

  return (
    <div className="TDB-HomePage__TeamUp__TeamCard">
      <img src={img} alt={name} />
      <Typography
        className="TDB-HomePage__TeamUp__TeamCard__Name"
        fontWeight="bold"
        fontSize="heading-4"
        color="neutral-900"
        align="center"
      >
        {name}
      </Typography>
      <Typography className="TDB-HomePage__TeamUp__TeamCard__Title" fontWeight="bold" fontSize="body-lg" color="neutral-600" align="center">
        {title}
      </Typography>
      <Typography
        className="TDB-HomePage__TeamUp__TeamCard__InterestsHeading"
        fontWeight="semi-bold"
        fontSize="subheading-2"
        color="neutral-600"
        align="center"
      >
        Interests
      </Typography>
      <Typography
        className="TDB-HomePage__TeamUp__TeamCard__Interests"
        fontWeight="medium"
        fontSize="body-lg"
        color="neutral-600"
        align="center"
      >
        {interests}
      </Typography>
      <Typography
        className="TDB-HomePage__TeamUp__TeamCard__UseCaseHeading"
        fontWeight="semi-bold"
        fontSize="subheading-2"
        color="neutral-600"
        align="center"
      >
        Use Case
      </Typography>
      <Typography
        className="TDB-HomePage__TeamUp__TeamCard__UseCase"
        fontWeight="medium"
        fontSize="body-lg"
        color="neutral-600"
        align="center"
      >
        {useCase}
      </Typography>
    </div>
  );
};

const TeamUp: React.FC = () => {
  return (
    <div className="TDB-HomePage__TeamUp">
      <Container>
        <div className="TDB-HomePage__TeamUp__Container">
          <div className="TDB-HomePage__TeamUp__Text">
            <Typography className="TDB-HomePage__TeamUp__Title" fontWeight="bold" fontSize="display-1" as="h3">
              Team up
            </Typography>
            <Typography className="TDB-HomePage__TeamUp__Subtitle" fontWeight="bold" fontSize="heading-2" color="neutral-900" as="h4">
              Jumpstart an ecosystem of collaboration
            </Typography>
            <Typography
              className="TDB-HomePage__TeamUp__Description"
              fontSize="subheading-3"
              fontWeight="medium"
              color="neutral-600"
              as="p"
            >
              From analytics down to the infrastructure, share data, code and all of your project assets for complete reproducibility, in a
              TCO model that works for everyone involved.
            </Typography>
            <CheckItem>Create teams</CheckItem>
            <CheckItem>Share assets, privately or publicly</CheckItem>
            <CheckItem>Control access and log everything</CheckItem>
            <CheckItem>Explore public data and code</CheckItem>
            <CheckItem>Pay only for your own usage</CheckItem>
          </div>
          <div className="TDB-HomePage__TeamUp__Cards">
            <TeamCard
              img={dorothy}
              name="Dorothy"
              title="Clinical Genomics"
              interests="Precision medicine, gene therapy"
              useCase="Save infant lives via rapid WGS and discovery of novel treatments"
            />
            <TeamCard
              img={tobe}
              name="Tobe"
              title="Senior Developer"
              interests="Python, scalable applications"
              useCase="Build clinical tools for querying massive datasets of genomic variants"
            />
            <TeamCard
              img={natalie}
              name="Natalie"
              title="Architect"
              interests="Data engineering, cloud development"
              useCase="Design secure tech stacks to accelerate collaboration across hospitals"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TeamUp;
